import React from 'react';
import './music.css';
import ReactPlayer from 'react-player';
import { YoutubeEmbed } from './component/youtube'
import guitar from '../../photos/Guitar.PNG';
import stage from '../../photos/stage.jpg';
import bg4 from '../../photos/bg4.png';
import music from '../../photos/music.mp4';
import axios from 'axios';
import { useEffect, useState } from 'react'
import { YoutubePl } from './component/youtubepl';
import YouTube from 'react-youtube';

const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';
const API_KEY = 'YOUR_API_KEY'; // Replace with your API key
const PLAYLIST_ID = 'YOUR_PLAYLIST_ID'; // Replace with your Playlist ID

async function fetchPlaylistItems() {
  try {
    const response = await axios.get(YOUTUBE_PLAYLIST_ITEMS_API, {
      params: {
        part: 'snippet',
        maxResults: 10, // Adjust based on your needs
        playlistId: PLAYLIST_ID,
        key: API_KEY,
      },
    });
    return response.data.items;
  } catch (error) {
    console.error('Error fetching playlist items:', error);
    return [];
  }
}

export const Music = () => {
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    fetchPlaylistItems().then(setVideos);
    
  }, []);
  


  return (
    <div className="music-container">
      <BackgroundImage />
      <Content />
      <div>
      {videos.map((video) => (
        <YouTube key={video.id} videoId={video.snippet.resourceId.videoId} />
      ))}
    </div>
    </div>
  );
};

const BackgroundImage = () => (
  <div className="background-image-container">
    <img src={bg4} alt="Background" />
  </div>
);

const Content = () => (
  <div className="content">
    <LiveShows />
  </div>
);



const LiveShows = () => (
  
  <div>
    <section className="music-intro" style={{marginBottom:'5vh'}}>
      <h2>Music</h2>
      <div className="music-description">
        Hello! I play Electric Guitar and Keys/Synth
      </div>
    </section>
    <section className="live-shows" style={{height:'100%'}}>

      <img src={guitar} alt="Guitar" style={{ objectFit: 'contain' }} className="live-show-item" />
      <video id="bannerVideo" autoPlay controls loop className="live-show-item">
        <source src={music} type="video/mp4" />
      </video>
      <img src={stage} alt="Stage" className="live-show-item" />
      <div style={{ maxWidth:'100%'}}className="react-player-container live-show-item">
        <ReactPlayer width='100%' height='100%' url="https://soundcloud.com/likapichu/guita-4" />
        <ReactPlayer width='100%' height='100%' url="https://soundcloud.com/likapichu/you-name" />
      </div>
      <div style={{display:'flex', margin:'auto', maxWidth:'100%'}}>
        <div style={{marginRight:'20px'}}><YoutubeEmbed embedId="_2q9drG5AWw"/></div>
        
        <YoutubeEmbed embedId="H4tpT77f37c" />
        
      </div>
      <div style={{margin:'auto'}}>
        <div>
          <h3>My Music Playlist</h3>
        </div>
        <YoutubePl PLAYLIST_ID="PLGxR3VavS487OHa_ZN4gEIV_Ks0uUF3sn" />
      </div>
      

    </section>
    </div>
);




